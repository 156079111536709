import styled, { css } from "styled-components";

const color = ({ $primary, $secondary }) => {
  if ($primary) {
    return css`
      background: #ffcd00;
      border-color: #ffcd00;
      color: #1d1d1b;
    `;
  }

  if ($secondary) {
    return css`
      background: #1d1d1b;
      border-color: #1d1d1b;
      color: #ffcd00;
    `;
  }

  return css`
    color: #1d1d1b;
    border-color: #1d1d1b;
  `;
};

const LinkButton = styled.a`
  text-decoration: none;

  font-size: 1.625rem;
  font-family: "Baloo 2", cursive;
  font-weight: 600;

  width: 11.85rem;
  height: 3.25rem;

  border-radius: 50px;
  border: 1px solid;

  display: flex;
  align-items: center;
  justify-content: center;

  ${color};
`;

export default LinkButton;
