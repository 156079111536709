import * as React from "react";
import styled, { css } from "styled-components";

import { container, blackSection, pointyAfter } from "../style/mixins";
import media from "../style/media";
import ContactButton from "../components/ContactButton";
import Arrow from "../components/Arrow";

const Section = styled.section`
  ${blackSection};
  position: relative;

  padding: 3rem 2rem;
  overflow-x: hidden;

  ${media.desktop`
      padding: 9.5rem 2rem 12.875rem 2rem;
  `}
`;

const Container = styled.div`
  ${container};
  position: relative;

  .content {
    ${media.desktop`
      max-width: 46rem;
    `}
  }

  h1 {
    position: relative; // force arrows behind text
    color: #ffcd00;
    margin: 0 0 2.5rem 0;

    font-size: 3rem;
    line-height: 1.2;

    ${pointyAfter};
    ::after {
      color: #fff;
    }
  }

  p {
    position: relative; // force arrows behind text
    margin: 0 0 2.5rem 0;

    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1.5;

    &.lead {
      margin: 0 0 2.5rem 0;

      font-size: 2rem;
      font-weight: 400;
      line-height: 1.3;
    }
  }
`;

const arrow = css`
  display: none;
  ${media.desktop`
    display: block;    
  `}
`;

const LeftArrow = styled.div`
  ${arrow};

  position: absolute;
  left: -14rem;
  bottom: 7rem;
  width: 5em;
`;

const ArrowOne = styled.div`
  ${arrow};
  position: absolute;
  left: 49rem;
  bottom: 0rem;
  width: 38rem;
`;

const ArrowTwo = styled.div`
  ${arrow};
  position: absolute;
  left: 61rem;
  bottom: -2rem;
  width: 16.5rem;
`;

const ArrowThree = styled.div`
  ${arrow};
  position: absolute;
  left: 61rem;
  bottom: 18rem;
  width: 27.5rem;
`;

const ArrowPairLeft = styled.div`
  ${arrow};
  position: absolute;
  left: 61rem;
  bottom: 29rem;
  width: 5rem;
`;
const ArrowPairRight = styled.div`
  ${arrow};
  position: absolute;
  left: 69rem;
  bottom: 29rem;
  width: 5rem;
`;

const Hero = () => (
  <Section>
    <Container>
      <LeftArrow>
        <Arrow stroke="#ffcd00" strokeWidth="17.6" />
      </LeftArrow>
      <ArrowOne>
        <Arrow stroke="#ffcd00" />
      </ArrowOne>
      <ArrowTwo>
        <Arrow stroke="#706f6f" />
      </ArrowTwo>
      <ArrowThree>
        <Arrow stroke="#1d1d1b" strokeWidth="3.6" />
      </ArrowThree>
      <ArrowPairLeft>
        <svg width="100%" height="100%" viewBox="0 0 80.091 68.192">
          <path
            d="M1437.355,350.293l-76.692,32.169,76.692,32.169"
            transform="translate(-1359.191 -348.366)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.945"
          />
        </svg>
      </ArrowPairLeft>
      <ArrowPairRight>
        <svg width="100%" height="100%" viewBox="0 0 80.091 68.192">
          <path
            d="M1497.561,350.293l76.692,32.169-76.692,32.169"
            transform="translate(-1495.634 -348.366)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.945"
          />
        </svg>
      </ArrowPairRight>

      <div className="content">
        <h1>UK payroll capability embedded in your product</h1>
        <p className="lead">
          Building payroll from scratch takes years. With Shape Embedded, you
          can use the Shape payroll API to delight your customers more and grow
          your business, today.
        </p>
        <p>
          Shape have spent 5 years building our collection of payroll tools so
          that you can embed payroll into your product in days.
        </p>
        <ContactButton $primary>get started</ContactButton>
      </div>
    </Container>
  </Section>
);

export default Hero;
