import * as React from "react";

const Bank = ({ stroke }) => (
  <svg viewBox="0 0 24 24">
    <path
      d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z"
      stroke={stroke}
      fill="none"
      strokeWidth="0.5"
    />
  </svg>
);

export default Bank;
