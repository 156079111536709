import * as React from "react";
import styled from "styled-components";

import { container, sectionPadding, whiteSection } from "../style/mixins";
import media from "../style/media";
import LinkButton from "../components/LinkButton";
import ContactButton from "../components/ContactButton";

const Section = styled.section`
  ${whiteSection};
  ${sectionPadding};
`;

const Container = styled.div`
  ${container};

  h2 {
    font-size: 2.2rem;
    line-height: 1.4;
    margin: 0 0 5rem 0;
    text-align: left;

    ${media.desktop`
      text-align: center;
    `}
  }
`;

const ProductHolder = styled.div`
  ${media.desktop`
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0 -1rem;
  `}
`;

const Product = styled.div`
  background: #ffcd00;
  color: #1d1d1b;
  border-radius: 1rem;

  margin: 3rem auto;
  padding: 2rem;

  max-width: 34rem;

  ${media.desktop`
    flex: 1;
  
    margin: 1rem;
    padding: 3rem;

    max-width: 34rem;
    display: flex;
    flex-direction: column;
  `}

  h3 {
    font-size: 3rem;
    margin: 0;

    border-bottom: 1px solid;

    span {
      font-weight: 400;

      ::before {
        content: "<";
        margin-right: 0.5rem;
      }
      ::after {
        content: ">";
        margin-left: 0.5rem;
      }
    }
  }

  p {
    font-size: 1.75rem;
    line-height: 1.5;
    flex: 1;
  }
`;

const ProductSection = () => (
  <Section>
    <Container>
      <h2>
        For over 5 years, Shape has been building a platform for fast, accurate
        payroll for UK businesses. With Shape Embedded, developers can now
        leverage our APIs and components to power their own products.
      </h2>
      <ProductHolder>
        <Product>
          <h3>
            shape <span>elements</span>
          </h3>
          <p>
            Our suite of pre build UI components to surface payroll flows in
            your product.
          </p>
          <ContactButton>get started</ContactButton>
        </Product>
        <Product>
          <h3>
            shape <span>API</span>
          </h3>
          <p>
            Run gross to net calculations, make HMRC RTI submissions, process
            pensions and integrate with pension providers.
          </p>
          <LinkButton $secondary href="https://api.shapepayroll.com/api">
            view docs
          </LinkButton>
        </Product>
      </ProductHolder>
    </Container>
  </Section>
);

export default ProductSection;
