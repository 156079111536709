import * as React from "react";

const Box = ({ stroke = "#fff", height = "152.611" }) => (
  <svg height={height} viewBox="0 0 133.662 152.611">
    <g transform="translate(-454.421 -2312.678)">
      <path
        d="M521.252,2314.178l-64.783,37.4v74.805l64.783,37.4,64.783-37.4V2351.58Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        d="M456.468,2351.763l64.783,37.22,64.783-37.22"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <line
        y1="74.805"
        transform="translate(521.252 2388.983)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default Box;
