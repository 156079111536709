import * as React from "react";
import styled from "styled-components";

import { container, yellowSection } from "../style/mixins";
import media from "../style/media";
import { StaticImage } from "gatsby-plugin-image";
import ContactButton from "../components/ContactButton";

const Section = styled.section`
  position: relative;
  ${yellowSection};

  padding: 3rem 2rem 0rem 2rem;
  ${media.desktop`
      padding: 5rem 2rem 0rem 2rem;
  `}
`;

const Container = styled.div`
  ${container};

  h2 {
    font-size: 2.5rem;
    margin: 0 0 3rem 0;
    text-align: left;

    ${media.desktop`
      text-align: center;
      font-size: 3rem;
      margin: 0 0 4rem 0;
    `}
  }
`;

const Wrapper = styled.div`
  ${media.tablet`
    display: flex;
    justify-content: center;    
  `}
`;

const Content = styled.div`
  max-width: 27rem;
  padding: 0 0 1rem 0;

  ${media.tablet`
    padding: 0 0 4rem 0;
  `}

  figure {
    margin: 2rem 0 3rem 0;
    line-height: 1.5;

    blockquote {
      font-size: 1.75rem;
      margin: 0;
    }

    figcaption {
      div:first-of-type {
        font-size: 1.75rem;
        font-weight: 600;
      }

      div:last-of-type {
        font-size: 1.25rem;
      }
    }
  }
`;

const Amount = styled.div`
  padding: 0 0 1rem 0;
  border-bottom: 1px solid;

  div {
    font-family: "Baloo 2", cursive;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 1;
    &.amount {
      font-size: 5rem;
      color: #fff;

      ${media.tablet`
        font-size: 6.475rem;
      `}
    }
  }

  ${media.desktop`
    padding: 2rem 0;
  `}
`;

const Alec = styled.div`
  max-width: 25rem;
  margin-left: auto;
  align-self: end;

  ${media.tablet`
    max-width: 35rem;
    margin-left: 4rem;
  `}

  ${media.desktop`
    max-width: 44rem;
    margin-left: 8rem;
  `}
`;

const AlecSection = () => (
  <Section>
    <Container>
      <h2>Payroll your way</h2>
      <Wrapper>
        <Content>
          <Amount>
            <div>We&rsquo;ve processed</div>
            <div className="amount">&pound;200m</div>
            <div>through Shape</div>
          </Amount>

          <figure>
            <blockquote>See what we can do for you!</blockquote>
            <figcaption>
              <div>Alec Middleton</div>
              <div>Sales Manager</div>
            </figcaption>
          </figure>
          <ContactButton>contact us</ContactButton>
        </Content>
        <Alec>
          <StaticImage src="../images/alec.png" alt="Alec" />
        </Alec>
      </Wrapper>
    </Container>
  </Section>
);

export default AlecSection;
