import * as React from "react";

const path = {
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "5",
};

const Stack = ({ stroke = "#fff", height = "152.611" }) => (
  <svg height={height} viewBox="0 0 133.665 152.611">
    <g transform="translate(-454.419 -2652.508)">
      <path
        d="M586.035,2691.411l-64.783-37.4-64.783,37.4"
        fill="none"
        stroke={stroke}
        {...path}
      />
      <g id="Group_19" data-name="Group 19">
        <path
          d="M456.468,2766.216l64.783,37.4,64.783-37.4"
          fill="none"
          stroke={stroke}
          {...path}
        />
        <path
          d="M456.468,2747.538l64.783,37.4,64.783-37.4"
          fill="none"
          stroke={stroke}
          {...path}
        />
        <path
          d="M456.468,2728.859l64.783,37.4,64.783-37.4"
          fill="none"
          stroke={stroke}
          {...path}
        />
        <path
          d="M456.468,2710.181l64.783,37.4,64.783-37.4"
          fill="none"
          stroke={stroke}
          {...path}
        />
        <path
          d="M456.468,2691.594l64.783,37.22,64.783-37.22"
          fill="none"
          stroke={stroke}
          {...path}
        />
      </g>
    </g>
  </svg>
);

export default Stack;
