import * as React from "react";

const Clock = ({ stroke }) => (
  <svg height="100%" width="100%" viewBox="0 0 66.783 66.783">
    <g transform="translate(-429.814 -3871.365)">
      <circle
        cx="32.392"
        cy="32.392"
        r="32.392"
        transform="translate(430.814 3872.365)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M446.733,3888.3l16.457,16.457.015,18.4"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </g>
  </svg>
);

export default Clock;
